<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>项目立项</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div v-if="detail" class="approval-detail-info">
            <el-button v-if="detail.infor.sign" class="printing" type="primary" @click="printDialogVisible = true">
                打印
            </el-button>
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name }}<i
                            :class="{red:detail.shenpi_num>1}">(第{{ detail.shenpi_num }}次提交)</i></div>
                        <div v-if="detail.status === 2 && detail.shenpi[detail.shenpi.length-1].state === 0"
                             class="state">立项审批中
                        </div>
                        <div v-else-if="detail.status === 0 && detail.rest_money.some(s=> !s.is_rest_money)"
                             class="state">财务对接人确认中
                        </div>
                        <div v-else class="state">{{ detail.status | state }}</div>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目类型：</span>
                    <span class="approval-detail-content">{{ detail.cat }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">二维码：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.qr_code" style="width: 150px; height: 150px"></el-image>
                    </div>
                </div>
            </div>
            <div class="approval-detail-container">
                <div class="approval-list-tip">立项信息</div>
                <div class="info-content">
                    <div class="active">尊敬的{{ detail.society }}：</div>
                    <div v-if="$isProjectTypeD(detail.cid)">为提升我国医疗发展水平，满足从业人员的相关需求，现申请由贵会主办“{{ detail.name }}”（{{
                            detail.cat }}）。本人愿担任{{ $getTitleByType('大会主席/项目负责人', detail.cid) }}，负责学术内容的组织与实施。
                    </div>
                    <div v-else-if="$isProjectTypeP(detail.cid)">
                        由本人作为本项目的学术负责人，现申请贵会担任申办方/资助方。
                    </div>
                    <div v-else>
                        由本人作为主要研究者发起的“{{ detail.name }}项目”，现申请贵会担任申办方/资助方。
                    </div>
                    <div>特此申请，望得到贵会批准。</div>
                </div>
                <div v-if="detail.infor.sign_time" class="approval-detail-title">
                    <span class="approval-detail-tip">申请时间：</span>
                    <span class="approval-detail-content">{{ detail.infor.sign_time }}</span>
                </div>
                <div v-if="detail.infor.sign" class="approval-detail-title">
                    <span class="approval-detail-tip">申请人签名：</span>
                    <div class="approval-detail-content">
                        <el-image :src="detail.infor.sign" style="width: 550px; height: auto"></el-image>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所在单位：</span>
                    <span class="approval-detail-content">{{ detail.infor.company }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}地点：</span>
                    <span class="approval-detail-content">{{ detail.infor.address }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}类型：</span>
                    <span class="approval-detail-content">{{ detail.infor.meet_type | meet_type(detail.cid) }}</span>
                </div>
                <div v-if="detail.infor.meet_type === 2 && $isProjectTypeD(detail.cid)" class="approval-detail-title">
                    <span class="approval-detail-tip">系列会议场次：</span>
                    <span class="approval-detail-content">{{ detail.infor.type_series }}</span>
                </div>
                <div v-if="$isProjectTypeD(detail.cid)">
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}形式：</span>
                        <span class="approval-detail-content">{{ detail.infor.meet_form | meet_form }}</span>
                    </div>
                    <div v-if="detail.infor.meet_info === 4" class="approval-detail-title">
                        <span class="approval-detail-tip">其他{{ $getTitleByType('会议', detail.cid) }}形式：</span>
                        <span class="approval-detail-content">{{ detail.infor.meet_info }}</span>
                    </div>
                </div>
                <div v-else class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}形式：</span>
                    <span class="approval-detail-content">{{ detail.infor.meet_info }}</span>
                </div>
                <div class="approval-detail-title" v-if="$isProjectTypeD(detail.cid)">
                    <span class="approval-detail-tip">线上平台：</span>
                    <span class="approval-detail-content">{{ detail.infor.platform }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">是否联合主办方：</span>
                    <span class="approval-detail-content">{{ detail.infor.if_union === 1 ? '否' : '是' }}</span>
                </div>
                <div v-if="detail.infor.if_union === 2" class="approval-detail-title">
                    <span class="approval-detail-tip">其他主办方：</span>
                    <span class="approval-detail-content">{{ detail.infor.sponsor }}</span>
                </div>
                <div class="approval-detail-title" v-if="$isProjectTypeD(detail.cid)">
                    <span class="approval-detail-tip">{{ $getTitleByType('项目单场规模', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.infor.scale | scale }}</span>
                </div>

                <div v-if="$isProjectTypeS(detail.cid)">
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">组长单位：</span>
                        <span class="approval-detail-content">{{ detail.infor.group_company }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">中心数量：</span>
                        <span class="approval-detail-content">{{ detail.infor.center_num === 1 ? '单中心' : '多中心' }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">是否需要伦理审批：</span>
                        <span class="approval-detail-content">{{ detail.infor.is_ethical_approval === 1 ? '需要' : '不需要'
                            }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">是否需要人遗办审批：</span>
                        <span class="approval-detail-content">{{ detail.infor.is_human_heritage_approval === 1 ? '需要' : '不需要'
                            }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">是否购买试验保险：</span>
                        <span class="approval-detail-content">{{ detail.infor.is_test_insurance === 1 ? '需要购买' : '不需要购买'
                            }}</span>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('是否需要委托会议执行公司', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.infor.if_entrust === 1 ? '需要' : '不需要' }}</span>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
                        <span>{{ detail.true_name | sub_name }}
                          <i class="el-icon-success"></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发出项目立项</div>
                        </div>
                    </div>

                    <div class="name-container active" v-if="detail.rest_money.length">
                        <span>财务
                           <i :class="detail.rest_money.every(s=> s.is_rest_money) ? 'el-icon-success' : 'el-icon-remove' "></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">财务对接人</div>
                            </div>
                            <div class="info-desc">
                                <div class="flex flex-direction margin-top-sm" v-for="(item,index) in detail.rest_money" :key="index">
                                    <div class="flex align-center justify-between margin-bottom-xs">
                                        <div>{{ item.sp_name }}</div>
                                        <div class="info-time">{{ item.is_rest_money ? item.sp_time : '确认中' }}</div>
                                    </div>
                                    <div v-if="item.is_rest_money">{{ item.is_rest_money === 1 ? '有结转资金' : '没有结转资金' }}
                                        <em v-if="item.is_rest_money === 1">:{{ item.rest_money }}</em></div>
                                    <div v-if="item.to_meeting">主任确认{{ item.to_meeting === 1 ? '转入' : '不转入' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="detail.rest_money.every(s=> s.is_rest_money)">
                        <div v-for="(item,index) in detail.shenpi" :key="item.id">
                            <div v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                 class="name-container active">
                        <span>
                            <span v-if="index === 0">申请人</span>
                            <span v-else>{{ item.true_name | sub_name }}</span>
                                     <i :class="item.state === 0 || item.state === 4 ? 'el-icon-remove' : item.state === 1
                                            ? 'el-icon-success' : 'el-icon-error' "></i>
                                </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{
                                                index === 0 ? '申请人' : item.true_name
                                            }}<i v-if="item.state != 0"
                                        >({{ item.state | sp_state(index) }})</i>
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="item.state === 0" class="info-desc">
                                        {{ isApprovaler ? "我（" : "" }}{{
                                            item.state | sp_state(index)
                                        }}{{ isApprovaler ? "）" : "" }}
                                    </div>
                                    <div v-if="item.state !== 0" class="info-desc">
                                        {{ item.sp_remark }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div v-for="item in detail.copy" :key="item.id"
                                     class="copy-members-name">
                                    <span>{{ item.true_name | sub_name }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <router-link :to="'/home/add?sign_code='+sign_code+'&id=' + pid">
                    <el-button
                        v-if="member_type == 1 && detail.status == 3"
                        class="approval-btn-right">重新提交
                    </el-button>
                </router-link>
                <el-button
                    v-if="isApprovaler && member_type == 1"
                    class="approval-btn-left"
                    @click="project_again">拒绝
                </el-button>
                <el-button
                    v-if="isApprovaler && member_type == 1"
                    :disabled="requestState"
                    :loading="requestState"
                    class="approval-btn-right" @click="approvalConfirm">确认立项
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-right"
                    @click="showDialog(1)">通过
                </el-button>
                <el-button
                    v-if="member_type == 0 && isRestMoneyApply"
                    class="approval-btn-right"
                    @click="rest_moneyVisible = true">确认
                </el-button>
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/projectDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item">
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%">
            <span slot="title" class="dialog-title">{{ state === 1 ? "审批同意确认" : "审批拒绝原因" }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因' "
                    :rows="5"
                    resize="none"
                    type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="rest_moneyVisible"
            center
            width="30%">
            <span slot="title" class="dialog-title">有无结转金额</span>
            <div class="dialog-container flex flex-direction">
                <el-radio-group v-model="is_rest_money">
                    <el-radio :label="1">有结转资金</el-radio>
                    <el-radio :label="2">没有结转资金</el-radio>
                </el-radio-group>
                <el-input class="margin-top-sm" v-model="rest_money" placeholder="请输入内容"  :rows="5"
                          resize="none"
                          type="textarea" v-if="is_rest_money === 1">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button :disabled="requestState" :loading="requestState" type="primary" @click="torestMoneyApproval()">确定</el-button>
            </span>
        </el-dialog>
        <project-print :detail="detail" :dialog-visible="printDialogVisible" @watchState="watchState"></project-print>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import config from "../../config/index";
import {getPathIndex, isProjectType, projectTypes, sub_name, ZHDate} from "../../tools";
import ProjectPrint from "./print/project";

export default {
    components: {ProjectPrint, ElButton},
    name: "paymentDetail",
    data() {
        return {
            pid: "",
            member_type: -1,

            isApprovaler: false,
            editVisible: false,
            requestState: false,
            state: -1,
            remark: "",
            rest_moneyVisible: false,
            isRestMoneyApply: false,
            is_rest_money: 1,
            rest_sid: 1,
            rest_money: "",
            sign_code: "",
            detail: null,
            printDialogVisible: false
        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "确认立项" : "";
        },
        closeDialog() {
            this.editVisible = false;
            this.rest_moneyVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 18, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 18, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        torestMoneyApproval() {
            if (this.is_rest_money === 1 && !this.rest_money) return this.$message.error("请输入金额");
            if (this.is_rest_money === 2) this.rest_money = ''
            this.requestState = true
            this.$api.apiContent
                .setRestMoney({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid,
                    is_rest_money: this.is_rest_money,
                    rest_money: this.rest_money,
                    sid: this.rest_sid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid,
                            is_rest_money: this.is_rest_money,
                            rest_money: this.rest_money,
                            sid: this.rest_sid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        project_again() {
            this.requestState = true
            this.$api.apiContent
                .project_again({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();

                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        approvalConfirm() {
            this.requestState = true
            this.$api.apiContent
                .project_true({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = Number(this.$route.query.type);
            this.$api.apiContent
                .getApProjectDetail({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });

                    res.data.qr_code = config.urlImgStr + res.data.qr_code;
                    res.data.society = res.data.society.map(item => {
                        return item.name
                    }).join(',')
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.end_time * 1000));
                    res.data.infor.sign = res.data.infor.sign ? config.urlImgStr + res.data.infor.sign : ''
                    res.data.infor.sign_time = res.data.infor.sign_time ? this.$tools.ZHDate(new Date(res.data.infor.sign_time * 1000)) : ''
                    this.detail = res.data
                    this.sign_code = res.data.sign_code;
                    let find = res.data.rest_money.find(f=>{ return f.sp_user_id === this.$store.state.user.mid && !f.is_rest_money});
                    if (find) {
                        this.rest_sid = find.sid;
                        this.isRestMoneyApply = true
                    } else {
                        this.isRestMoneyApply = false
                    }


                    this.isApproval();
                })
                .catch((e) => {
                    console.log(e)
                    this.detail = {};
                });
        },

        isApproval: function () {
            this.isApprovaler = false;
            this.editVisible = false;
            this.requestState = false
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index <= 1) {
                        this.isApprovaler = this.detail.status === 1;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = this.member_type == 0
                        }
                    }

                }
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待主任确认";
                case 1:
                    return "待确认";
                case 3:
                    return "已拒绝请重新申请";
                default:
                    return "已立项";
            }
        },

        meet_type(type, cid) {
            return projectTypes.find(f => {
                return f.type === isProjectType(cid) && f.key === type
            }).value
        },
        meet_form(type) {
            switch (Number(type)) {
                case 1:
                    return "线下会议";
                case 2:
                    return "线上会+线下会";
                case 3:
                    return "线上会议";
                default:
                    return "其他";
            }
        },
        scale(type) {
            switch (Number(type)) {
                case 1:
                    return "50人以下";
                case 2:
                    return "51-100人";
                case 3:
                    return "101-150人";
                case 4:
                    return "151-200人";
                default:
                    return "200人以上";
            }
        },
        sp_state(type, index) {
            switch (Number(type)) {
                case 0:
                    return index === 0 ? "等待主任确认" : index === 1 ? '等待发起人确认' : '审批中';
                case 3:
                case 4:
                    return index === 0 ? "等待主任确认" : '已拒绝';
                case 1:
                    return index === 0 ? "主任已确认" : '已同意';
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;

                            .info-file {
                                color: #3b77e7;
                                margin-top: 12px;
                            }

                            .info-file-up {
                                position: relative;
                                width: 250px;
                                background: transparent;
                                border: 1px solid #3b77e7;
                                font-size: 12px;
                                margin-top: 12px;
                                padding-left: 50px;
                                color: #3b77e7;

                                .info-file-up-logo {
                                    position: absolute;
                                    top: 25%;
                                    left: 20px;
                                }
                            }
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        flex-shrink: 0;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

        }

        .approval-list-tip {
            font-size: 18px;
            color: #333333;
            flex-shrink: 0;
            margin-top: 12px;
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .info-content {
                padding: 30px;
                margin-left: 30px;
                font-size: 18px;
                line-height: 28px;
                text-indent: 2rem;

                .active {
                    text-indent: 0;
                }
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-file {
                    font-size: 18px;
                    flex: 7;
                    flex-shrink: 1;
                    color: #3b77e7;
                }

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
