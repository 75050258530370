<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">项目立项</div>
                <div class="info-content active">
                    <div class="active">尊敬的{{ detail.society }}：</div>
                    <div  v-if="$isProjectTypeD(detail.cid)">为提升我国医疗发展水平，满足从业人员的相关需求，现申请由贵会主办“{{ detail.name }}”（{{
                            detail.cat }}）。本人愿担任{{ $getTitleByType('大会主席/项目负责人', detail.cid) }}，负责学术内容的组织与实施。
                    </div>
                    <div v-else-if="$isProjectTypeP(detail.cid)">
                        由本人作为本项目的学术负责人，现申请贵会担任申办方/资助方。
                    </div>
                    <div v-else>
                        由本人作为主要研究者发起的“{{ detail.name }}项目”，现申请贵会担任申办方/资助方。
                    </div>
                    <div>特此申请，望得到贵会批准。</div>
                </div>
                <div v-if="detail.infor.sign_time" class="approval-detail-title">
                    <span class="approval-detail-tip">申请时间：</span>
                    <span class="approval-detail-content">{{ detail.infor.sign_time }}</span>
                </div>
                <div v-if="detail.infor.sign" class="approval-detail-title">
<!--                    <span class="approval-detail-tip">申请人签名：</span>-->
                    <div class="approval-detail-content">
                        <el-image :src="detail.infor.sign" style="width: 450px; height: auto"></el-image>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目类型：</span>
                    <span class="approval-detail-content">{{ detail.cat }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所在单位：</span>
                    <span class="approval-detail-content">{{ detail.infor.company }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}地点：</span>
                    <span class="approval-detail-content">{{ detail.infor.address }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}类型：</span>
                    <span class="approval-detail-content">{{ detail.infor.meet_type | meet_type }}</span>
                </div>
                <div v-if="detail.infor.meet_type === 2" class="approval-detail-title">
                    <span class="approval-detail-tip">系列会议场次：</span>
                    <span class="approval-detail-content">{{ detail.infor.type_series }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.cid) }}形式：</span>
                    <span class="approval-detail-content">{{ detail.infor.meet_form | meet_form }}</span>
                </div>
                <div v-if="detail.infor.meet_info === 4" class="approval-detail-title">
                    <span class="approval-detail-tip">其他{{ $getTitleByType('会议', detail.cid) }}形式：</span>
                    <span class="approval-detail-content">{{ detail.infor.meet_info }}</span>
                </div>
                <div class="approval-detail-title" v-if="$isProjectTypeD(detail.cid)">
                    <span class="approval-detail-tip">线上平台：</span>
                    <span class="approval-detail-content">{{ detail.infor.platform }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">是否联合主办方：</span>
                    <span class="approval-detail-content">{{ detail.infor.if_union === 1 ? '否' : '是' }}</span>
                </div>
                <div v-if="detail.infor.if_union === 2" class="approval-detail-title">
                    <span class="approval-detail-tip">其他主办方：</span>
                    <span class="approval-detail-content">{{ detail.infor.sponsor }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('项目单场规模', detail.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.infor.scale | scale }}</span>
                </div>

                <div v-if="$isProjectTypeS(detail.cid)">
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">组长单位：</span>
                        <span class="approval-detail-content">{{ detail.infor.group_company }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">中心数量：</span>
                        <span class="approval-detail-content">{{ detail.infor.center_num }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">是否需要伦理审批：</span>
                        <span class="approval-detail-content">{{ detail.infor.is_ethical_approval === 1 ? '需要' : '不需要'
                            }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">是否需要人遗办审批：</span>
                        <span class="approval-detail-content">{{ detail.infor.is_human_heritage_approval === 1 ? '需要' : '不需要'
                            }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">是否需要试验保险：</span>
                        <span class="approval-detail-content">{{ detail.infor.is_test_insurance === 1 ? '需要购买' : '不需要购买' }}</span>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">是否需要委托会议执行公司：</span>
                    <span class="approval-detail-content">{{ detail.infor.if_entrust === 1 ? '需要' : '不需要' }}</span>
                </div>
<!--                <div class="approval-detail-container">-->
<!--                    <div class="approval-detail-title">-->
<!--                        &lt;!&ndash;                        <span class="approval-detail-tip">签名：</span>&ndash;&gt;-->
<!--                        <div class="approval-detail-content">-->
<!--                            <el-image :src="detail.sign" style="width: 100%; height: auto"></el-image>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="approval-list-container">
                    <div class="approval-list-tip">审批流程</div>

                    <div class="approval-list">
                        <div class="name-container active">
                <span>{{ detail.true_name | sub_name }}
                  <i class="el-icon-success"></i>
                </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div class="info-time">{{ detail.add_time | formatTime }}</div>
                                </div>
                                <div class="info-desc">发出项目立项</div>
                            </div>
                        </div>
                        <div class="name-container active" v-if="detail.rest_money.length">
                        <span>财务
                           <i :class="detail.rest_money.every(s=> s.is_rest_money) ? 'el-icon-success' : 'el-icon-remove' "></i>
                        </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">财务对接人</div>
                                </div>
                                <div class="info-desc">
                                    <div class="flex flex-direction margin-top-sm" v-for="(item,index) in detail.rest_money" :key="index">
                                        <div class="flex align-center justify-between">
                                            <div>{{ item.sp_name }}</div>
                                            <div class="info-time">{{ item.is_rest_money ? item.sp_time : '确认中' }}</div>
                                        </div>
                                        <div v-if="item.is_rest_money">{{ item.is_rest_money === 1 ? '有结转资金' : '没有结转资金' }}
                                            <em v-if="item.is_rest_money === 1">:{{ item.rest_money }}万元</em></div>
                                        <div v-if="item.to_meeting">主任确认{{ item.to_meeting === 1 ? '转入' : '不转入' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="detail.rest_money.every(s=> s.is_rest_money)">
                           <div v-for="(item,index) in detail.shenpi" :key="item.id">
                            <div v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                 class="name-container" :class="{active:index !== detail.shenpi.length - 1}">
                                <span>
                                    <span v-if="index === 0">申请人</span>
                                    <span v-else>{{ item.true_name | sub_name }}</span>
                                     <i :class="item.state === 0 || item.state === 4 ? 'el-icon-remove' : item.state === 1
                                            ? 'el-icon-success' : 'el-icon-error' "></i>
                                </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{
                                                index === 0 ? '申请人' : item.true_name
                                            }}<i v-if="item.state != 0"
                                        >({{ item.state | sp_state(index) }})</i>
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="item.state === 0" class="info-desc">
                                        {{ isApprovaler ? "我（" : "" }}{{
                                            item.state | sp_state(index)
                                        }}{{ isApprovaler ? "）" : "" }}
                                    </div>
                                    <div v-if="item.state !== 0" class="info-desc">
                                        {{ item.sp_remark }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {isProjectType, projectTypes, sub_name, ZHDate} from "../../../tools";

export default {
    name: "project-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
    },
    methods: {
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待主任确认";
                case 1:
                    return "待确认";
                case 3:
                    return "已拒绝请重新申请";
                default:
                    return "已立项";
            }
        },

        meet_type(type,cid) {
            return projectTypes.find(f=>{ return f.type === isProjectType(cid) && f.key === type }).value
        },
        meet_form(type) {
            switch (Number(type)) {
                case 1:
                    return "线下会议";
                case 2:
                    return "线上会+线下会";
                case 3:
                    return "线上会议";
                default:
                    return "其他";
            }
        },
        scale(type) {
            switch (Number(type)) {
                case 1:
                    return "50人以下";
                case 2:
                    return "51-100人";
                case 3:
                    return "101-150人";
                case 4:
                    return "151-200人";
                default:
                    return "200人以上";
            }
        },
        sp_state(type, index) {
            switch (Number(type)) {
                case 0:
                    return index === 0 ? "等待主任确认" : index === 1 ? '等待发起人确认' : '审批中';
                case 3:
                case 4:
                    return index === 0 ? "等待主任确认" : '已拒绝';
                case 1:
                    return index === 0 ? "主任已确认" : '已同意';
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;

    &::-webkit-scrollbar {
        display: none;
    }
}

#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}
.info-content {

    font-size: 18px;
    line-height: 28px;

    text-indent: 0;
    .active {
        padding: 10px;
        text-indent: 2rem;
    }
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-tip-length {
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>